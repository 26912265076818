
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import HoriSlide from "@/components/horiSlide/HoriSlide.vue";
import { getFarmingDetail } from "../../../../api/farmer";
import { dataType, farmingDetailType } from "./stateType";
import { Router, useRoute, useRouter } from "vue-router";
import { previewImage } from "@/utils/util"

const states: dataType = {
  farmingId: "",
  farmingDetailData: {},
};

export default defineComponent({
  setup() {
    const state = reactive<dataType>(states);
    const { getFarmingDetailData } = useNetwork(state);
    const $route = useRoute();
    const params = $route.params;
    const $router = useRouter();
    const { onRecord, preview } = useEvent(state, $router);
    onMounted(() => {
      state.farmingId = params.id as string;
      getFarmingDetailData();
    });
    return {
      ...toRefs(state),
      onRecord,
      preview
    };
  },
  components: {
    HoriSlide,
  },
});

// 事件相关hooks
const useEvent = (state: dataType, router?: Router) => {
  const onRecord = () => {
    router &&
      router.push({
        path: "/farmingRecord",
        query: {
          typeId: (state.farmingDetailData as any).farmingType,
          typeName: (state.farmingDetailData as any).farmingTypeName,
          name: (state.farmingDetailData as any).plantFarmingName,
          id: (state.farmingDetailData as any).id
        },
      });
  };
  const preview = (e:any,i:number) => {
    previewImage(e,i)
  }
  return {
    onRecord,
    preview
  };
};

// 接口相关hooks
const useNetwork = (state: dataType) => {
  const getFarmingDetailData = async () => {
    const result: any = await getFarmingDetail({ id: state.farmingId });
    if (result.data.code == 0) {
      const tempData: farmingDetailType = result.data.data;
      tempData.plantFarmingImgList = [];
      tempData.phaseImgList = [];
      const phaseList = tempData.phaseImg ? tempData.phaseImg.split(",") : []
      if (tempData.phaseImg) {
        tempData.phaseImgList = phaseList;
      }
      const plantList =tempData.plantFarmingImg ? tempData.plantFarmingImg.split(",") : []
      if (tempData.plantFarmingImg) {
        tempData.plantFarmingImgList = plantList;
      }
      state.farmingDetailData = tempData;
    }
  };
  return {
    getFarmingDetailData,
  };
};
