// 函数节流
export default class Throttle {
    static callback: any;
    static timeout: any = 300;
    static timers: any;

    public static init(callback: Function, timeOut: number) {
        this.callback = callback;
        this.timeout = timeOut;
    }
    public static search(val: any): void {
        if (this.timers) {
            clearTimeout(this.timers)
        }
        this.timers = setTimeout(() => {
            this.callback(val);
        }, this.timeout)
    }
}