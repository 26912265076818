
import { defineComponent, nextTick, onMounted, reactive, toRefs,SetupContext } from "vue";
import { stateType } from "./HoriSlideType";
import Throttle from "@/utils/throttle";
const useEvent = (state: stateType) => {
  const onScroll = (e: any) => {
    // 当滚动距离超过dom总宽度时，就是到底了，触发事件
    if (e.target.scrollLeft >= state.domSum) {
      Throttle.search(null);
    }
  };
  return {
    onScroll,
  };
};
const states: stateType = {
  singDomW: 0,
  domSum: 0,
  dataSum: 0,
};
export default defineComponent({
  setup(props, context:SetupContext) {
    const state = reactive<stateType>(states);
    const { onScroll } = useEvent(state);
    onMounted(() => {
      // 使用函数节流防止多次触发
      Throttle.init(() => {
        context.emit('bottom')
      }, 500);
      nextTick(() => {
        const tempDom = document.getElementById("slideContainer");
        const children: any = tempDom?.children;
        if (children.length > 0) {
          state.dataSum = children.length;
          state.singDomW = children[0].offsetWidth - children[0].offsetLeft;
          // 数据总数 * 单条数据 - 屏幕内的一条数据 - 20（距离20px时触发事件）
          state.domSum = children.length * state.singDomW - state.singDomW - 20;
        }
      });
    });
    return {
      ...toRefs(state),
      onScroll,
    };
  },
});
