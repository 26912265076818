import { http } from "../src/utils/index"

export const getFarmingList = (p: any) => {
    return http({
        url: '/h5/index/my/farming',
        method: 'GET',
        params: p
    })
}

// 获取品种
export const getVarietyList = () => {
    return http({
        url: 'h5/farmer/info/source/typeAll',
        method: 'GET'
    })
}

// 获取资源
export const getResourceData = (p: any) => {
    return http({
        url: '/h5/index/my/source',
        method: 'GET',
        params: p
    })
}

// 获取农事详情
export const getFarmingDetail = (p: any) => {
    return http({
        url: '/h5/farming/option/detail',
        method: 'GET',
        params: p
    })
}

// 忽略农事
export const igonreFarming = (p: any) => {
    return http({
        url: '/h5/farming/option/ignore',
        method: 'GET',
        params: p
    })
}

// 保存农事记录
export const saveFarmingRecord = (data:any) => {
    return http({
        url: '/h5/farming/record/index',
        method: 'POST',
        data
    })
}